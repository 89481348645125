const EVENTS = {
  GameManager: {
    CLOCK_TICK: 'GameManager.CLOCK_TICK',
    FOUND_WORD: 'GameManager.FOUND_WORD',
    GAME_WON: 'GameManager.GAME_WON',
    NEW_GAME: 'GameManager.NEW_GAME',
    WORD_SELECTED: 'GameManager.WORD_SELECTED'
  },
  LetterGridCol: {
    HOVERED: 'LetterGridCol.HOVERED',
    PRESSED: 'LetterGridCol.PRESSED',
    RESIZED: 'LetterGridCol.RESIZED'
  },
  NewGameButton: {
    CLICK: 'NewGameButton.CLICK'
  },
  PuzzleManager: {
    PUZZLE_GENERATED: 'PuzzleGenerator.PUZZLE_GENERATED'
  },
  TopNav: {
    CLOSED: 'TopNav.CLOSED',
    OPENED: 'TopNav.OPENED'
  },
  TopNavButton: {
    CLICK: 'TopNavButton.CLICK'
  },
  WordSelection: {
    CHANGE: 'WordSelection.CHANGE',
    CLEAR: 'WordSelection.CLEAR',
    START: 'WordSelection.START'
  }
}

export { EVENTS }
