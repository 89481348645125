import { HTMLController } from '../utils/html-controller.js'
import { Heap } from '../utils/heap.js'
import { Stopwatch } from '../utils/stopwatch.js'
import { track } from '../utils/track.js'
import { EVENTS } from '../events.js'

function clamp (min, max, nums) {
  return nums.map(num => Math.max(min, Math.min(max, num)))
}

class Jumble {
  constructor (letters) {
    this.letters = letters.map(row => row.map(letter => letter.toUpperCase()))
  }

  wordFrom (start, end) {
    const direction = clamp(-1, 1, [
      end.y - start.y,
      end.x - start.x
    ])

    let word = ''
    let currX = start.x
    let currY = start.y
    while (
      currY < this.letters.length &&
      currX < this.letters[currY].length
    ) {
      word += this.letters[currY][currX]

      if (currX === end.x && currY === end.y) {
        break
      }

      currY += direction[0]
      currX += direction[1]
    }

    return word
  }
}

class GameManager extends HTMLController {
  constructor () {
    super(...arguments)
    // The max heap that stores the best puzzles generated so far
    this.puzzles = new Heap(puzzle => puzzle.scores.total, { maxSize: 25 })

    this.words = null
    this.jumble = null
    this.timer = new Stopwatch()
  }

  created () {
    this.subscribe(EVENTS.PuzzleManager.PUZZLE_GENERATED, this.savePuzzle)
    this.subscribe(EVENTS.NewGameButton.CLICK, this.startNewGame)
    this.subscribe(EVENTS.WordSelection.CHANGE, this.checkSelection)
    document.addEventListener('visibilitychange', () => this.pauseOrUnpauseTimer())

    const tick = () => {
      if (this.timer.isRunning) {
        this.publish(EVENTS.GameManager.CLOCK_TICK, { msTaken: this.timer.time })
      }
      window.requestAnimationFrame(tick)
    }
    window.requestAnimationFrame(tick)
  }

  get foundWordsCount () {
    return Array.from(this.words.values()).filter(x => x === true).length
  }

  get gameIsOver () {
    return Array.from(this.words.values()).every(x => x === true)
  }

  checkSelection ({ start, end }) {
    const word = this.jumble.wordFrom(start, end)

    this.publish(EVENTS.GameManager.WORD_SELECTED, { word })

    this.checkWord(word)
    this.checkWord(word.split('').reverse().join(''))

    if (this.gameIsOver) {
      const finalTime = this.timer.stop()
      this.publish(EVENTS.GameManager.GAME_WON, {
        msTaken: finalTime,
        wordCount: this.words.size
      })
      track('Game Complete', {
        category: 'Game Interactions',
        value: finalTime
      })
    }
  }

  checkWord (word) {
    if (this.words.has(word) && this.words.get(word) === false) {
      this.words.set(word, true)
      this.publish(EVENTS.GameManager.FOUND_WORD, { word })
      track('Word Found', {
        category: 'Game Interactions',
        label: `Word #${this.foundWordsCount}`,
        value: this.timer.time
      })
      return true
    }
    return false
  }

  pauseOrUnpauseTimer () {
    if (document.hidden) {
      this.timer.pause()
    } else if (this.timer.time > 0) {
      this.timer.start()
    }
  }

  savePuzzle ({ puzzle }) {
    this.puzzles.push(puzzle)
  }

  async startNewGame () {
    const puzzle = await new Promise(resolve => {
      const timer = setInterval(() => {
        if (this.puzzles.size > 0) {
          clearInterval(timer)
          resolve(this.puzzles.pop())
        }
      })
    })

    this.words = new Map(puzzle.words.map(word => [word.toUpperCase(), false]))
    this.jumble = new Jumble(puzzle.letters)

    if (this.timer.isRunning) {
      this.timer.stop()
    }
    this.timer.start()

    this.publish(EVENTS.GameManager.NEW_GAME, {
      answers: puzzle.answers,
      letters: puzzle.letters,
      words: puzzle.words
    })
  }
}

export {
  GameManager
}
