import { HTMLController } from '../utils/html-controller.js'
import { EVENTS } from '../events.js'

function directionFromStartEnd (start, end) {
  // Calculate the angle of the direction of movement relative to the origin
  const rad = Math.atan2( // In radians
    end.y - start.y,
    end.x - start.x
  )
  const deg = rad * (180 / Math.PI) // In degrees

  // Make sure we only allow angles than can highlight a word
  const roundedDeg = roundToNearest(45, deg)

  // Pick a direction based on our allowed angle
  const direction = {
    '-180': [0, -1], // Left
    '-135': [-1, -1], // Up-Left
    '-90': [-1, 0], // Up
    '-45': [-1, 1], // Up-Right
    0: [0, 1], // Right
    45: [1, 1], // Down-Right
    90: [1, 0], // Down
    135: [1, -1], // Down-Left
    180: [0, -1] // Left
  }[roundedDeg]

  if (!direction) {
    throw new Error(`Unrecognized selection angle: ${roundedDeg}`)
  }

  return {
    y: direction[0],
    x: direction[1]
  }
}

function roundToNearest (roundTo, value) {
  return Math.round(value / roundTo) * roundTo
}

/*
  The base selection class, representing a highlight series of letters spanning
  the spaces between two coordinate pairs.
*/
const PRIVATE = Symbol('Private instance variables')
const GRID_PADDING_LEFT = 20
const GRID_PADDING_TOP = 10
class WordSelection extends HTMLController {
  constructor () {
    super(...arguments)
    Object.assign(this, {
      [PRIVATE]: {
        start: null,
        end: null
      },
      letterWidth: null,
      gridWidth: null,
      gridHeight: null
    })
  }

  created () {
    this.subscribe(EVENTS.LetterGridCol.RESIZED, this.updateLetterWidth)
    this.subscribe(EVENTS.GameManager.NEW_GAME, this.updateGridDimensions)
  }

  get diagonalLetterWidth () {
    return Math.sqrt(Math.pow(this.letterWidth, 2) * 2)
  }

  get direction () {
    if (!this.start || !this.end) {
      return null
    }

    return directionFromStartEnd(this.start, this.end)
  }

  get start () {
    return this[PRIVATE].start
  }

  set start (coords) {
    this[PRIVATE].start = coords
    this.render()
  }

  get end () {
    return this[PRIVATE].end
  }

  set end (coords) {
    if (coords === null) {
      this[PRIVATE].end = null
      this.render()
      return
    }

    // This setter snaps the end coordinates to the closest permitted selection
    // Figure out how many letters we're moving
    const letterDelta = Math.max(
      Math.abs(coords.x - this.start.x),
      Math.abs(coords.y - this.start.y)
    )

    // Iterate from the starting position in the given direction until we reach
    // the end of the highlighted region or we reach the edge of the grid
    const direction = directionFromStartEnd(this.start, coords)
    const curr = { ...this.start }
    let i = 0
    while (
      i++ < letterDelta &&
      (curr.y > 0 || direction.y > -1) &&
      (curr.x > 0 || direction.x > -1) &&
      (curr.y < (this.gridHeight - 1) || direction.y < 1) &&
      (curr.x < (this.gridWidth - 1) || direction.x < 1)
    ) {
      curr.y += direction.y
      curr.x += direction.x
    }

    // Actually set the end coordinates
    this[PRIVATE].end = curr

    this.render()
  }

  updateGridDimensions ({ letters }) {
    this.gridHeight = letters.length
    this.gridWidth = letters[0].length

    this.render()
  }

  updateLetterWidth ({ width }) {
    this.letterWidth = width

    this.render()
  }

  render () {
    if (!this.start || !this.end) {
      return
    }

    const deltaX = this.end.x - this.start.x
    const deltaY = this.end.y - this.start.y

    const direction = this.direction
    const isDiagonal = Math.abs(direction.x) === 1 && Math.abs(direction.y) === 1
    if (isDiagonal) {
      this.el.classList.add('diagonal')
    } else {
      this.el.classList.remove('diagonal')
    }

    // Calculate the dimensions of the selection.
    const letterCount = Math.max(
      Math.abs(deltaX) + 1,
      Math.abs(deltaY) + 1
    )
    const width = letterCount *
    (isDiagonal ? this.diagonalLetterWidth : this.letterWidth) -
    (isDiagonal ? this.diagonalLetterWidth * 0.25 : 0)

    this.el.style.width = `${width}px`
    this.el.style.height = `${this.letterWidth * (isDiagonal ? 0.75 : 1.0)}px`

    // Position and rotate the selection
    const x = this.letterWidth * this.start.x + GRID_PADDING_LEFT
    const y = this.letterWidth * this.start.y + GRID_PADDING_TOP
    const angleRads = Math.atan2(deltaY, deltaX)
    this.el.style.top = `${(isDiagonal ? 0.125 : 0) * this.letterWidth}px`
    this.el.style.transform = `
      translate(${x}px, ${y}px)
      rotate(${angleRads}rad)
    `
    this.el.style.transformOrigin = `
      ${this.letterWidth * 0.5}px
      ${this.letterWidth * (isDiagonal ? 0.375 : 0.5)}px
    `
  }
}

/*
  The selection controlled by the user.
*/
class ActiveSelection extends WordSelection {
  created () {
    super.created(...arguments)
    this.subscribe(EVENTS.LetterGridCol.PRESSED, this.startSelection)
    this.subscribe(EVENTS.LetterGridCol.HOVERED, this.updateSelection)
    this.subscribe(EVENTS.GameManager.FOUND_WORD, this.clearSelection)
    document.addEventListener('mouseup', () => this.clearSelection())
    document.addEventListener('touchend', () => this.clearSelection(), { passive: true })
  }

  clearSelection () {
    this.start = null
    this.end = null
    this.publish(EVENTS.WordSelection.CLEAR)

    this.render()
  }

  startSelection ({ coords }) {
    if (this.start !== null) {
      throw new Error('Started multiple selections')
    }

    this.start = coords
    this.end = coords
    this.publish(EVENTS.WordSelection.START, { start: this.start })
    this.publish(EVENTS.WordSelection.CHANGE, {
      start: this.start,
      end: this.end
    })

    this.render()
  }

  updateSelection ({ coords }) {
    if (this.start === null) {
      return
    }

    this.end = coords
    this.publish(EVENTS.WordSelection.CHANGE, {
      start: this.start,
      end: this.end
    })

    this.render()
  }

  render () {
    super.render()
    this.el.hidden = !(this.start && this.end)
  }
}

/*
  The selections that highlight a particular word. Revealed once found.
*/
class AnswerSelection extends WordSelection {
  created () {
    super.created(...arguments)
    this.subscribe(EVENTS.GameManager.NEW_GAME, this.updateWord)
    this.subscribe(EVENTS.GameManager.FOUND_WORD, this.checkWord)
  }

  get index () {
    return parseInt(this.el.dataset.index, 10)
  }

  get word () {
    return this.el.dataset.word
  }

  set word (val) {
    if (val) {
      this.el.dataset.word = val
    } else {
      delete this.el.dataset.word
    }
  }

  checkWord ({ word }) {
    if (word.toUpperCase() === this.word.toUpperCase()) {
      this.el.hidden = false
    }
  }

  updateWord ({ words, answers }) {
    this.el.hidden = true
    if (words.length > this.index) {
      this.word = words[this.index]
      this.start = answers[this.word].start
      this.end = answers[this.word].end
    } else {
      this.word = null
      this.start = null
      this.end = null
    }
  }
}

export {
  ActiveSelection,
  AnswerSelection
}
