import { HTMLController } from '../utils/html-controller.js'
import { EVENTS } from '../events.js'

class GameInstructions extends HTMLController {
  created () {
    this.subscribe(EVENTS.GameManager.NEW_GAME, this.hide)
  }

  hide () {
    this.el.hidden = true
  }
}

export {
  GameInstructions
}
