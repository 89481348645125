import { track } from '../utils/track.js'
import { HTMLController } from '../utils/html-controller.js'
import { EVENTS } from '../events.js'

function stopPropagation (e) {
  e.stopPropagation()
}

class TopNavButton extends HTMLController {
  created () {
    this.el.addEventListener('click', stopPropagation)
    this.el.addEventListener('click', () => this.publishClick())
    this.subscribe(EVENTS.TopNav.OPENED, this.markOpen)
    this.subscribe(EVENTS.TopNav.CLOSED, this.markClosed)
  }

  publishClick () {
    this.publish(EVENTS.TopNavButton.CLICK)
  }

  markClosed () {
    this.el.setAttribute('aria-expanded', false)
  }

  markOpen () {
    this.el.setAttribute('aria-expanded', true)
  }
}

class TopNav extends HTMLController {
  created () {
    this.subscribe(EVENTS.TopNavButton.CLICK, this.handleTopNavButtonClick)
    document.addEventListener('click', () => this.handleBackdropClick())
  }

  get isOpen () {
    return this.el.getAttribute('aria-expanded') === 'true'
  }

  set isOpen (val) {
    if (val) {
      this.el.setAttribute('aria-expanded', 'true')
    } else {
      this.el.setAttribute('aria-expanded', 'false')
    }
  }

  handleBackdropClick () {
    if (this.isOpen) {
      this.close()
      this.trackBackdropClick()
    }
  }

  handleTopNavButtonClick () {
    this.toggle()
    this.trackTopNavButtonClick()
  }

  trackBackdropClick () {
    track('Top Nav Close', {
      label: 'Backdrop Click',
      category: 'Navigation'
    })
  }

  trackTopNavButtonClick () {
    track(this.isOpen ? 'Top Nav Open' : 'Top Nav Close', {
      label: 'Hamburger Click',
      category: 'Navigation'
    })
  }

  toggle () {
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  close () {
    this.isOpen = false
    this.publish(EVENTS.TopNav.CLOSED)
  }

  open () {
    this.isOpen = true
    this.publish(EVENTS.TopNav.OPENED)
  }
}

export {
  TopNav,
  TopNavButton
}
