import { EVENTS } from '../events.js'
import { HTMLController } from '../utils/html-controller.js'
import { track } from '../utils/track.js'

class PuzzleManager extends HTMLController {
  constructor () {
    super(...arguments)
    this.hasGeneratedPuzzle = false
    /*
      Wire up the worker thread that generates new puzzles.

      It continuously generates and scores puzzles, then publishes them to the
      main thread. The main thread maintains a heap of the ones with the best
      scores as reported by the worker thread. That heap serves as a priority
      queue that will serve up the best puzzle we've generated so far on
      request.

      NOTE: worker-plugin doesn't support using window.Worker for some reason
    */
    this.puzzleGeneratorWorker = new Worker('../entrypoints/worker.js')

    this.puzzleGeneratorWorker.addEventListener('message', msg => {
      switch (msg.data.type) {
        case 'NEW_PUZZLE':
          this.publishNewPuzzle(msg.data.puzzle)
          break
        default:
          throw new Error(`Unrecognized message type: ${msg.data.type}`)
      }
    })

    // This is a hook that allows us to set the puzzle in E2E and integration
    // tests for a consistent test run. It isn't possible to set a random
    // seed in the browser, otherwise I'd probably do that instead.
    document.addEventListener('PUZZLE_OVERRIDE', (e) => {
      this.publishNewPuzzle({
        answers: e.detail.answers,
        letters: e.detail.letters,
        scores: {
          total: Infinity
        },
        words: e.detail.words
      })
    })
  }

  publishNewPuzzle (puzzle) {
    this.publish(EVENTS.PuzzleManager.PUZZLE_GENERATED, { puzzle })
    if (!this.hasGeneratedPuzzle) {
      track('timing_complete', {
        category: 'Page Load',
        interaction: false,
        name: 'First Puzzle Loaded',
        value: Math.round(window.performance.now())
      })
      this.hasGeneratedPuzzle = true
    }
  }
}

export { PuzzleManager }
