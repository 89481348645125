import { HTMLController } from '../utils/html-controller.js'
import { formatMS } from '../utils/format-ms.js'
import { EVENTS } from '../events.js'

class PuzzleTimer extends HTMLController {
  created () {
    this.subscribe(EVENTS.GameManager.CLOCK_TICK, this.updateText)
    this.subscribe(EVENTS.GameManager.GAME_WON, this.updateText)
  }

  updateText ({ msTaken }) {
    this.el.textContent = formatMS(msTaken)
  }
}

export {
  PuzzleTimer
}
