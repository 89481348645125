import { HTMLController } from '../utils/html-controller.js'
import { EVENTS } from '../events.js'

class WordListItem extends HTMLController {
  created () {
    this.subscribe(EVENTS.GameManager.FOUND_WORD, this.checkWord)
    this.subscribe(EVENTS.GameManager.NEW_GAME, this.changeWord)
  }

  get index () {
    return parseInt(this.el.dataset.index, 10)
  }

  get word () {
    return this.el.textContent.toLowerCase()
  }

  set word (val) {
    if (val) {
      this.el.hidden = false
      this.el.textContent = val
    } else {
      this.el.hidden = true
      this.el.textContent = ''
    }

    this.isFound = false
  }

  get isFound () {
    return this.el.dataset.isFound === 'true'
  }

  set isFound (val) {
    if (val) {
      this.el.dataset.isFound = 'true'
    } else {
      delete this.el.dataset.isFound
    }
  }

  changeWord ({ words }) {
    this.word = words[this.index] || ''
  }

  checkWord ({ word }) {
    if (word.toLowerCase() === this.word) {
      this.isFound = true
    }
  }
}

export {
  WordListItem
}
