import { track } from '../utils/track.js'
import { HTMLController } from '../utils/html-controller.js'

class CookieBanner extends HTMLController {
  created () {
    if (!this.el.dataset.version) {
      throw new Error('CookieBanner missing data-version attribute')
    }

    if (window.localStorage.getItem('cookieAck')) {
      this.el.hidden = true
    } else {
      this.el.hidden = false
      track('Banner Displayed', {
        category: 'Cookies',
        label: this.el.dataset.version,
        interaction: false
      })
    }

    this.refs.ackBtn.addEventListener('click', () => this.handleAckBtnClick())
  }

  handleAckBtnClick () {
    window.localStorage.setItem('cookieAck', '1')
    this.el.hidden = true
    track('Accepted', {
      category: 'Cookies',
      label: this.el.dataset.version,
      value: window.performance.now()
    })
  }
}

export {
  CookieBanner
}
