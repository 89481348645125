import { HTMLController } from '../utils/html-controller.js'
import { EVENTS } from '../events.js'

function getElCoords (el) {
  const coords = el.dataset.coords
  if (!coords) {
    throw new Error('LetterGridCol missing data-coords attribute')
  }
  const [y, x] = coords.split(',').map(x => parseInt(x, 10))
  if (Number.isNaN(x) || Number.isNaN(y)) {
    throw new TypeError(`Expected data-coords to be two integers in the format "y,x"; Got ${coords}`)
  }

  return { x, y }
}

class LetterGrid extends HTMLController {
  created () {
    this.el.addEventListener('mousedown', e => this.publishPressed(e))
    this.el.addEventListener('mousemove', e => this.publishHovered(e.target))

    this.el.addEventListener('touchstart', e => this.publishPressed(e), { passive: false })
    this.el.addEventListener('touchmove', e => this.fireMousemove(e), { passive: false })
  }

  fireMousemove (e) {
    if (e.target.dataset.controller !== 'LetterGridCol') {
      return
    }
    // Touchmove events always fire on the originating element.
    // We don't want that, as we need to target the element that is
    // currently under the user's finger.
    //
    // Fire a synthatic mousemove event at it.
    e.preventDefault()
    const touch = e.touches[0]
    const targetEl = document.elementFromPoint(touch.clientX, touch.clientY)
    if (targetEl !== null) {
      this.publishHovered(targetEl)
    }
  }

  publishPressed (e) {
    if (e.target.dataset.controller !== 'LetterGridCol') {
      return
    }
    if (e.type === 'touchstart' && e.cancelable) {
      e.preventDefault()
    }
    this.publish(EVENTS.LetterGridCol.PRESSED, { coords: getElCoords(e.target) })
  }

  publishHovered (el) {
    if (el.dataset.controller !== 'LetterGridCol') {
      return
    }
    this.publish(EVENTS.LetterGridCol.HOVERED, { coords: getElCoords(el) })
  }
}

class LetterGridCol extends HTMLController {
  created () {
    this.subscribe(EVENTS.GameManager.NEW_GAME, this.updateLetter)

    // These should all be the same size; only one need publish size updates
    if (this.isSizePublisher) {
      window.addEventListener('resize', () => this.publishSize(), { passive: true })
      window.addEventListener('load', () => this.publishSize(), { once: true })
    }
  }

  get isSizePublisher () {
    return this.el.dataset.isSizePublisher
  }

  publishSize () {
    const bounds = this.el.getBoundingClientRect()
    this.publish(EVENTS.LetterGridCol.RESIZED, bounds)
  }

  updateLetter ({ letters }) {
    const { x, y } = getElCoords(this.el)
    if (letters.length > y && letters[0].length > x) {
      this.refs.letter.textContent = letters[y][x]
      this.el.hidden = false
    } else {
      this.el.hidden = true
    }
  }
}

export {
  LetterGrid,
  LetterGridCol
}
