import 'regenerator-runtime/runtime'

import { HTMLControllerRegistry } from '../utils/html-controller.js'

import { ActiveSelection, AnswerSelection } from '../controllers/word-selection.js'
import { CompletionModal } from '../controllers/completion-modal.js'
import { CookieBanner } from '../controllers/cookie-banner.js'
import { GameInstructions } from '../controllers/game-instructions.js'
import { GameManager } from '../controllers/game-manager.js'
import { LetterGrid, LetterGridCol } from '../controllers/letter-grid-col.js'
import { NewGameButton, RefreshGameButton } from '../controllers/new-game-button.js'
import { PuzzleManager } from '../controllers/puzzle-manager.js'
import { PuzzleTimer } from '../controllers/puzzle-timer.js'
import { SelectedText } from '../controllers/selected-text.js'
import { TopNav, TopNavButton } from '../controllers/top-nav.js'
import { WordListItem } from '../controllers/word-list-item.js'

const registry = new HTMLControllerRegistry()

registry.define('ActiveSelection', ActiveSelection)
registry.define('AnswerSelection', AnswerSelection)
registry.define('CompletionModal', CompletionModal)
registry.define('CookieBanner', CookieBanner)
registry.define('GameInstructions', GameInstructions)
registry.define('GameManager', GameManager)
registry.define('LetterGrid', LetterGrid)
registry.define('LetterGridCol', LetterGridCol)
registry.define('NewGameButton', NewGameButton)
registry.define('PuzzleManager', PuzzleManager)
registry.define('PuzzleTimer', PuzzleTimer)
registry.define('RefreshGameButton', RefreshGameButton)
registry.define('SelectedText', SelectedText)
registry.define('TopNav', TopNav)
registry.define('TopNavButton', TopNavButton)
registry.define('WordListItem', WordListItem)

registry.mount()
