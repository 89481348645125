function leftPad (str, n, filler = '0') {
  str = String(str)
  while (str.length < n) {
    str = filler + str
  }
  return str
}

export {
  leftPad
}
