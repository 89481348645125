/*
  A Max-Heap interface that is actually backed by a sorted Array.

  Using native Array methods benchmarked faster than an implementation that
  followed a real Heap insertion and removal algorithm. It's also waaaaaay
  simpler.
*/
const HEAP = Symbol('Internal heap representation')
class Heap {
  constructor (scoreFn, { maxSize = Infinity } = {}) {
    this[HEAP] = []
    this.scoreFn = scoreFn
    this.maxSize = maxSize
  }

  get size () {
    return this[HEAP].length
  }

  push (data) {
    const score = this.scoreFn(data)
    const i = this[HEAP].findIndex(node => node.score < score)
    this[HEAP].splice(i, 0, { data, score })
    if (this.size > this.maxSize) {
      this[HEAP].splice(this.maxSize)
    }
  }

  peek () {
    return this.size > 0 ? this[HEAP][0].data : null
  }

  pop () {
    return this.size > 0 ? this[HEAP].shift().data : null
  }
}

export { Heap }
