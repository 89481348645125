import { HTMLController } from '../utils/html-controller.js'
import { EVENTS } from '../events.js'

class SelectedText extends HTMLController {
  created () {
    this.subscribe(EVENTS.GameManager.WORD_SELECTED, this.updateText)
  }

  updateText ({ word }) {
    this.el.textContent = word || ''
  }
}

export {
  SelectedText
}
