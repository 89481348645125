function track (action, {
  category,
  label,
  value,
  interaction = true,
  name = undefined
}) {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
    non_interaction: !interaction,
    name
  })
}

export {
  track
}
