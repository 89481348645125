const START = Symbol('A boundary marking the beginning of the list')
const END = Symbol('A boundary marking the end of the list')
class DoublyLinkedList {
  constructor () {
    // Each of these Maps acts as a singly linked list pointing in opposite
    // directions.
    this.nexts = new Map([[START, END]])
    this.prevs = new Map([[END, START]])
  }

  get size () {
    return this.nexts.size - 1 // The "- 1" compensates for the `null` key
  }

  get last () {
    const node = this.prevs.get(END)
    return node === START ? null : node
  }

  get first () {
    const node = this.nexts.get(START)
    return node === END ? null : node
  }

  has (node) {
    return this.nexts.has(node) && this.prevs.has(node)
  }

  push (node) {
    this.insertAfter(this.prevs.get(END), node)
  }

  pop () {
    return this.remove(this.prevs.get(END))
  }

  unshift (node) {
    this.insertBefore(this.nexts.get(START), node)
  }

  shift () {
    return this.remove(this.nexts.get(START))
  }

  insertBefore (anchorNode, nodeToInsert) {
    const oldBefore = this.prevs.get(anchorNode)
    insertBetween.call(this, oldBefore, anchorNode, nodeToInsert)
  }

  insertAfter (anchorNode, nodeToInsert) {
    const oldAfter = this.nexts.get(anchorNode)
    insertBetween.call(this, anchorNode, oldAfter, nodeToInsert)
  }

  remove (node) {
    if (!this.has(node)) {
      return null
    }

    const before = this.prevs.get(node)
    const after = this.nexts.get(node)
    this.nexts.set(before, after)
    this.prevs.set(after, before)

    if (node !== START && node !== END) {
      this.nexts.delete(node)
      this.prevs.delete(node)
    }

    return node
  }

  forEach (callback, thisArg) {
    let node = this.nexts.get(START)
    let i = 0
    while (node !== END) {
      callback.call(thisArg, node, i, this)
      node = this.nexts.get(node)
      i++
    }
  }
}

function insertBetween (former, latter, node) {
  if (
    this.nexts.has(node) ||
    this.prevs.has(node)
  ) {
    throw new Error(`Inserted node is already present in the list: "${node}"`)
  }

  this.prevs.set(node, former)
  this.nexts.set(node, latter)
  this.nexts.set(former, node)
  this.prevs.set(latter, node)
}

export {
  DoublyLinkedList
}
