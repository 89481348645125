import { DoublyLinkedList } from './linked-list.js'

class EventBus {
  constructor ({ log = console.error } = {}) {
    this.subscriptions = new Map()
    this.log = log
  }

  publish (eventType, data = {}) {
    if (typeof eventType !== 'string') {
      throw new TypeError(`EventBus.publish expected first argument of type string. Got ${Object.prototype.toString.call(eventType)}`)
    }

    if (!this.subscriptions.has(eventType)) {
      return
    }

    const handlers = this.subscriptions.get(eventType)
    for (const [handler, contexts] of handlers.entries()) {
      contexts.forEach(context => {
        handler.call(context, data)
      })
    }
  }

  subscribe (eventType, handler, context) {
    if (typeof eventType !== 'string') {
      throw new TypeError(`EventBus.subscribe expected first argument of type string. Got ${Object.prototype.toString.call(eventType)}`)
    } else if (typeof handler !== 'function') {
      throw new TypeError(`EventBus.subscribe expected second argument of type function. Got ${Object.prototype.toString.call(handler)}`)
    }

    if (!this.subscriptions.has(eventType)) {
      this.subscriptions.set(eventType, new Map())
    }
    const handlers = this.subscriptions.get(eventType)

    if (!handlers.has(handler)) {
      handlers.set(handler, new DoublyLinkedList())
    }
    const contexts = handlers.get(handler)

    if (!contexts.has(context)) {
      contexts.push(context)
    }

    let subscribed = true
    const unsubscribe = () => {
      if (!subscribed) {
        return
      }

      contexts.remove(context)
      if (contexts.size === 0) {
        handlers.delete(handler)
      }
      if (handlers.size === 0) {
        this.subscriptions.delete(eventType)
      }
      subscribed = false
    }

    return unsubscribe
  }
}

export {
  EventBus
}
