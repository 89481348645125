import { formatMS } from '../utils/format-ms.js'
import { HTMLController } from '../utils/html-controller.js'
import { EVENTS } from '../events.js'

class CompletionModal extends HTMLController {
  created () {
    this.subscribe(EVENTS.GameManager.NEW_GAME, this.close)
    this.subscribe(EVENTS.GameManager.GAME_WON, this.open)
  }

  close () {
    this.el.hidden = true
    this.refs.timeEl.textContent = ''
    this.refs.timePerWordEl.textContent = ''
  }

  open ({ msTaken, wordCount }) {
    const secsPerWord = (msTaken / wordCount / 1000).toFixed(2)
    this.refs.timeEl.textContent = `You found ${wordCount} words in ${formatMS(msTaken)}`
    this.refs.timePerWordEl.textContent = `That's ${secsPerWord} second${secsPerWord !== '1.00' ? 's' : ''} per word!`

    this.el.hidden = false
  }
}

export {
  CompletionModal
}
