import { EventBus } from './event-bus.js'

let instantiatedDirectly = true
class HTMLController {
  constructor (el, registry) {
    if (instantiatedDirectly) {
      console.warn(
        `${this.constructor.name} was instantiated directly.`,
        'Instead, use HTMLControllerRegistry.define to manage the lifecycle of',
        'your HTMLController subclasses.'
      )
    }

    const controllerName = this.constructor.name
    Object.defineProperties(this, {
      el: {
        value: el
      },

      refs: {
        value: new Proxy(Object.create(null), {
          get: function (obj, prop) {
            // Breadth first search for in-scope elements with the data-ref(s)
            // attribute. Searchable area is bounded by controller roots.
            // In other words, a controlled element and it's grandchild that is
            // also controlled cannot hold refs to the same element.
            //
            // I'm sure there's a way to do this that performs all the work when
            // updates occur. This scales linearly with child elements that are
            // in scope and will do a lot of repeated work if a ref is re-used
            // (which I expect will happen a lot).
            let ref = null
            let childEls = Array.from(el.children)
            while (childEls.length > 0) {
              const currEl = childEls.shift()
              // If the current element is controlled, stop descending
              // This maintains better encapsulation; only one controller at a time
              // should be touching an element at
              if (currEl.hasAttribute(registry.controllerAttr)) {
                continue
              }

              // Collect data-ref elements
              if (currEl.getAttribute(registry.refAttr) === prop) {
                if (ref !== null) {
                  throw new Error(
                    `${controllerName} already has an element assigned`,
                    `to ref ${prop}. Did you mean to use data-refs?`
                  )
                }
                ref = currEl
              }

              // Collect data-refs elements
              if (currEl.getAttribute(registry.refsAttr) === prop) {
                if (ref !== null && !Array.isArray(ref)) {
                  throw new Error(
                    `${controllerName} already has an element assigned`,
                    `to ref ${prop}. Did you mean to use data-refs?`
                  )
                } else if (ref === null) {
                  ref = []
                }
                ref.push(currEl)
              }

              // Add children to searchable area
              childEls = childEls.concat(Array.from(currEl.children))
            }

            return ref
          },
          set: () => {
            throw new Error('HTMLController.refs is read-only')
          },
          deleteProperty: () => {
            throw new Error('HTMLController.refs is read-only')
          }
        })
      },

      publish: {
        value: function (eventType, data) {
          return registry.publish(eventType, data)
        }
      },

      subscribe: {
        value: function (eventType, handler) {
          return registry.subscribe(eventType, handler, this)
        }
      }
    })
  }

  /*
    Called once the controller has been instantiated.

    This is a good place to perform rendering, state setup, binding instances
    methods that will be used as event handlers, and adding those event
    handlers which will not be removed for the lifetime of the component.
  */
  created () {}

  /*
    Called when the controlled element is added to the DOM.

    If a controller should stop listening for global events while its element
    is not on the DOM, this is a good place to remove those event handlers.
  */
  connected () {}

  /*
    Called when the controlled element is removed from the DOM.

    The same controller instances will continue to wrap the element and if the
    element is re-attached, the same controller instance will continue to
    control it.

    If the controlled element is being discarded, this is where you should
    clean up any global event handlers.
  */
  disconnected () {}
}

class HTMLControllerRegistry {
  constructor () {
    Object.defineProperties(this, {
      definedControllers: {
        value: new Map()
      },
      nodeControllers: {
        value: new WeakMap()
      }
    })

    this.controllerAttr = null
  }

  get isMounted () {
    return Boolean(this.controllerAttr)
  }

  getControllerByName (controllerName) {
    return this.definedControllers.get(controllerName) || null
  }

  define (controllerName, controllerClass) {
    if (typeof controllerName !== 'string') {
      const displayName = `${controllerName}` || Object.prototype.toString.call(controllerName)
      throw new TypeError([
        'controllerRegistry.define expected first argument of string.',
        `Got "${displayName}"`
      ].join(' '))
    } else if (!(controllerClass && controllerClass.prototype instanceof HTMLController)) {
      const displayName = `${controllerClass}` || Object.prototype.toString.call(controllerClass)
      throw new TypeError([
        'controllerRegistry.define expected second argument to be subclass',
        `of ${HTMLController.name}. Got "${displayName}"`
      ].join(' '))
    } else if (this.getControllerByName(controllerName)) {
      throw new Error([
        `"${controllerName}" has already been registered for HTMLController`,
        `"${this.getControllerByName(controllerName).name}"`
      ].join(' '))
    }

    this.definedControllers.set(controllerName, controllerClass)

    if (
      this.isMounted &&
      document.readyState !== 'loading'
    ) {
      document.querySelectorAll(`[${this.controllerAttr}="${controllerName}"]`)
        .forEach(el => this.createNodeController(el))
    }
  }

  getNodeController (el) {
    return this.nodeControllers.get(el) || null
  }

  connectNode (node) {
    const controller = this.getNodeController(node)
    if (controller) {
      controller.connected()
    } else {
      this.createNodeController(node)
    }
  }

  // NOTE: Only call this for nodes that are attached to the DOM
  createNodeController (node) {
    if (this.nodeControllers.has(node)) {
      throw new Error(
        `${node} already has a controller:`,
        `${this.nodeControllers.get(node).name}`
      )
    }

    const controllerName = node.getAttribute(this.controllerAttr)
    const ControllerClass = this.getControllerByName(controllerName)
    if (ControllerClass) {
      instantiatedDirectly = false
      const newController = new ControllerClass(node, this)
      instantiatedDirectly = true

      this.nodeControllers.set(node, newController)

      newController.created()
      newController.connected()
    }
  }

  disconnectNode (node) {
    const controller = this.getNodeController(node)
    if (controller) {
      controller.disconnected()
    }
  }

  mount (
    mountPoint = document.body,
    {
      controllerAttr = 'data-controller',
      eventBus = new EventBus(),
      refAttr = 'data-ref',
      refsAttr = 'data-refs'
    } = {}
  ) {
    Object.defineProperties(this, {
      controllerAttr: {
        value: controllerAttr
      },

      eventBus: {
        value: eventBus
      },

      refAttr: {
        value: refAttr
      },

      refsAttr: {
        value: refsAttr
      },

      publish: {
        value: function (eventType, data) {
          return eventBus.publish(eventType, data)
        }
      },

      subscribe: {
        value: function (eventType, handler, thisArg) {
          return eventBus.subscribe(eventType, handler, thisArg)
        }
      }
    })

    new window.MutationObserver(mutations => mutations.forEach(m => {
      if (m.type === 'childList') {
        Array.from(m.addedNodes)
          .filter(isElement)
          .forEach(n => this.connectNode(n))

        Array.from(m.removedNodes)
          .filter(isElement)
          .forEach(n => this.disconnectNode(n))
      } else if (
        m.type === 'attributes' &&
        m.attributeName === this.controllerAttr
      ) {
        this.createNodeController(m.target)
      }
    }))
      .observe(mountPoint, {
        attributes: true,
        childList: true,
        subtree: true
      })

    const createAllControllers = () => {
      document.querySelectorAll(`[${this.controllerAttr}]`)
        .forEach(el => this.createNodeController(el))
    }

    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', createAllControllers)
    } else {
      createAllControllers()
    }
  }
}

function isElement (node) {
  return node.nodeType === window.Node.ELEMENT_NODE
}

export {
  HTMLController,
  HTMLControllerRegistry
}
