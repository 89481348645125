const LAST_STARTED_AT = Symbol('started at')
const TIME_ELAPSED = Symbol('total time elapsed')
class Stopwatch {
  constructor () {
    this[LAST_STARTED_AT] = null
    this[TIME_ELAPSED] = 0
  }

  get isRunning () {
    return this[LAST_STARTED_AT] !== null
  }

  get time () {
    const t = this[LAST_STARTED_AT] ? Date.now() - this[LAST_STARTED_AT] : 0
    return this[TIME_ELAPSED] + t
  }

  pause () {
    if (this.isRunning) {
      this[TIME_ELAPSED] += Date.now() - this[LAST_STARTED_AT]
      this[LAST_STARTED_AT] = null
    }
  }

  start () {
    if (this.isRunning) {
      throw new Error('Stopwatch already started')
    }

    this[LAST_STARTED_AT] = Date.now()
  }

  stop () {
    const finalTime = this.time
    this[LAST_STARTED_AT] = null
    this[TIME_ELAPSED] = 0
    return finalTime
  }
}

export { Stopwatch }
