import { track } from '../utils/track.js'
import { HTMLController } from '../utils/html-controller.js'
import { EVENTS } from '../events.js'

class NewGameButton extends HTMLController {
  created () {
    if (!this.el.dataset.trackingLabel) {
      throw new TypeError(`${this.constructor.name}.el missing require attribute data-tracking-label`)
    }

    this.el.addEventListener('click', () => this.publishClick())
    this.el.addEventListener('click', () => this.trackClick())
  }

  publishClick () {
    this.publish(EVENTS.NewGameButton.CLICK)
  }

  trackClick () {
    track('Start Game', {
      category: 'Game Interactions',
      label: this.el.dataset.trackingLabel
    })
  }
}

class RefreshGameButton extends NewGameButton {
  created () {
    super.created()

    this.el.addEventListener('click', () => this.startSpinEffect())
    this.el.addEventListener('animationend', () => this.endSpinEffect())
    this.subscribe(EVENTS.GameManager.NEW_GAME, this.show)
    this.subscribe(EVENTS.GameManager.GAME_WON, this.hide)
  }

  endSpinEffect () {
    this.el.classList.remove('spin')
    this.el.disabled = false
  }

  startSpinEffect () {
    this.el.disabled = true
    this.el.classList.add('spin')
  }

  show () {
    this.el.hidden = false
  }

  hide () {
    this.el.hidden = true
  }
}

export {
  NewGameButton,
  RefreshGameButton
}
