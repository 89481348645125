import { leftPad } from '../utils/left-pad.js'

function formatMS (ms) {
  const MS_PER_SECOND = 1000
  const MS_PER_MINUTE = MS_PER_SECOND * 60

  const minutes = Math.floor(ms / MS_PER_MINUTE)

  ms -= minutes * MS_PER_MINUTE

  const seconds = Math.floor(ms / MS_PER_SECOND)

  ms -= seconds * MS_PER_SECOND

  return `${minutes}:${leftPad(seconds, 2)}.${Math.floor(ms / 100)}`
}

export {
  formatMS
}
